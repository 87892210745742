
export const SERVER_PORT = process.env.REACT_APP_SERVER_PORT;
export const SERVER_URL = process.env.REACT_APP_SERVER_URL;
export const API_BASE_URL = `${SERVER_URL}:${SERVER_PORT}/`;
export const REACT_APP_II_SUPABASE_URL = process.env.REACT_APP_II_SUPABASE_URL;
export const REACT_APP_II_SUPABASE_KEY = process.env.REACT_APP_II_SUPABASE_ANON_KEY;


export const app_name = 'IntellectInbox';

