const commonTimezones = [
    { label: 'UTC - Coordinated Universal Time', value: 'UTC' },
    { label: 'EST - Eastern Standard Time (US & Canada)', value: 'America/New_York' },
    { label: 'CST - Central Standard Time (US & Canada)', value: 'America/Chicago' },
    { label: 'MST - Mountain Standard Time (US & Canada)', value: 'America/Denver' },
    { label: 'PST - Pacific Standard Time (US & Canada)', value: 'America/Los_Angeles' },
    { label: 'CET - Central European Time', value: 'Europe/Berlin' },
    { label: 'AEST - Australian Eastern Standard Time', value: 'Australia/Sydney' },
    { label: 'IST - Indian Standard Time', value: 'Asia/Kolkata' },
    // Add more timezones as necessary
];

export default commonTimezones;